<div class="flex flex-row justify-between space-x-2">
  <div class="mat-card-header" mat-dialog-title removeClass="mat-dialog-title">
    <mat-icon class="mat-card-avatar-icon">
      {{ data.icon }}
    </mat-icon>
    <div class="mat-card-header-text">
      <div class="mat-card-title">
        {{ data.title }}
      </div>
      <div class="mat-card-subtitle">
        {{ data.subtitle }}
      </div>
    </div>
  </div>
</div>

<div
  mat-dialog-content
  style="display: block; min-width: 40rem; max-width: 40rem; max-height: 60vh">
  <div *ngIf="data.description">
    <div class="font-medium mb-2">{{ 'general.description' | translate }}</div>
    <markdown-description
      [description]="data.description"></markdown-description>
  </div>

  <div class="overflow-x-hidden" [class.mt-6]="data.description">
    <div class="font-medium mb-2">URLs</div>
    <ol class="p-0 m-0 mx-6">
      <li *ngFor="let url of data.urls">
        <a class="link w-fit" externalLink [href]="url"
          ><span class="text-ellipsis">{{ url }}</span></a
        >
      </li>
    </ol>
  </div>
</div>

<div class="w-full justify-end flex flex-row" mat-dialog-actions>
  <button mat-button color="default" [mat-dialog-close]="null">
    {{ 'general.close' | translate }}
  </button>
</div>
