<h1 mat-dialog-title>
  {{ 'policy_expression.timespan_restriction_title' | translate }}
</h1>
<mat-dialog-content>
  <div class="flex flex-col">
    <!-- Date Range -->
    <mat-form-field
      *ngIf="group.controls.range; let ctrl"
      class="mt-[20px]"
      color="accent">
      <mat-label>{{
        'policy_definition_page.date_range' | translate
      }}</mat-label>
      <mat-date-range-input required [formGroup]="ctrl" [rangePicker]="picker">
        <input
          matStartDate
          formControlName="start"
          [placeholder]="'general.start_date' | translate" />
        <input
          matEndDate
          formControlName="end"
          [placeholder]="'general.end_date' | translate" />
      </mat-date-range-input>
      <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <mat-error *ngIf="ctrl.invalid">{{
        validationMessages.invalidDateRangeMessage
      }}</mat-error>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flex flex-row justify-end">
  <button mat-button [mat-dialog-close]="null">
    {{ 'general.cancel' | translate }}
  </button>

  <button
    color="primary"
    mat-raised-button
    [disabled]="!group.valid"
    (click)="onAdd()">
    {{ 'general.add' | translate }}
  </button>
</mat-dialog-actions>
