<edit-asset-form-label
  [label]="label ?? ('general.language' | translate)"
  [ctrl]="control"></edit-asset-form-label>
<mat-form-field class="w-full mt-1" color="accent">
  <mat-select [formControl]="control" [compareWith]="'id' | compareByField">
    <mat-option [value]="null">-</mat-option>
    <mat-option *ngFor="let item of items.highlightItems" [value]="item">
      {{ item.label }}
    </mat-option>
    <mat-divider
      *ngIf="
        items.highlightItems.length && items.otherItems.length
      "></mat-divider>
    <mat-option *ngFor="let item of items.otherItems" [value]="item">
      {{ item.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
